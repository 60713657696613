let commons = require('../utils/commons');

function mobileMenuInit() {
    const menuOpenLink = document.getElementById('mobile-menu-open-link');
    if (menuOpenLink === undefined || !menuOpenLink) {
        console.log('mobileMenuInit: no menuOpenLink found by id "mobile-menu-open-link"');
        return false;
    }
    const menuCloseLink = document.getElementById('mobile-menu-close');
    if (menuCloseLink === undefined || !menuCloseLink) {
        console.log('mobileMenuInit: no menuCloseLink found by id "mobile-menu-close"');
        return false;
    }
    const menuBodyNode = document.getElementsByClassName('menu-body')[0];
    if (menuBodyNode === undefined || !menuBodyNode) {
        console.log('mobileMenuInit: no menuBodyNode found by id "menu-body"');
        return false;
    }
    const menuBackgroundNode = document.getElementsByClassName('menu-background')[0];
    if (menuBackgroundNode === undefined || !menuBackgroundNode) {
        console.log('mobileMenuInit: no menuBackgroundNode found by id "menu-background"');
        return false;
    }
    this.toggleMenu = function() {
        // menu
        if (commons.hasClassName(menuBodyNode, 'closed')) {
            commons.addClassName(menuBodyNode, 'animated');
            commons.removeClassName(menuBodyNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBodyNode, 'animated');
            }, 350);
        } else {
            commons.addClassName(menuBodyNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBodyNode, 'closed');
            }, 350);
        }
        // background
        if (commons.hasClassName(menuBackgroundNode, 'closed')) {
            commons.addClassName(menuBackgroundNode, 'animated');
            commons.removeClassName(menuBackgroundNode, 'closed');
            setTimeout(function() {
                commons.removeClassName(menuBackgroundNode, 'animated');
            }, 250);
        } else {
            commons.addClassName(menuBackgroundNode, 'animated');
            setTimeout(function() {
                commons.addClassName(menuBackgroundNode, 'closed');
            }, 250);
        }
    }
    menuOpenLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    menuCloseLink.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    menuBackgroundNode.addEventListener('click', function(e) {
        e.preventDefault();
        this.toggleMenu();
    }.bind(this));
    //additional close buttons
    const addMenuCloseLinks = document.getElementsByClassName('add-mobile-menu-close');
    for(let i = 0; i < addMenuCloseLinks.length; i++) {
        let addMenuCloseLink = addMenuCloseLinks.item(i);
        addMenuCloseLink.addEventListener('click', function(e) {
            e.preventDefault();
            this.toggleMenu();
        }.bind(this));
    }
    //additional open buttons
    const addMenuOpenLinks = document.getElementsByClassName('add-mobile-menu-open');
    for(let i = 0; i < addMenuOpenLinks.length; i++) {
        let addMenuOpenLink = addMenuOpenLinks.item(i);
        addMenuOpenLink.addEventListener('click', function(e) {
            e.preventDefault();
            this.toggleMenu();
        }.bind(this));
    }
}

document.addEventListener('DOMContentLoaded', function(){
    const mobileMenuObj = new mobileMenuInit();
});
