import baseStyles from '../sass/base.scss';
let commons = require('./utils/commons');
const WOW = require('wowjs');
import mobileMenuJS from './includes/mobile_menu.js';
import fancyboxJS from './fancybox/jquery.fancybox.min.js';
import customFancyboxJS from './includes/custom_fancybox.js';
import customScrollsJS from './includes/custom_scrolls.js';
import commonSliderJS from './includes/common_slider.js';

window.wow = new WOW.WOW(
    {

    }
);
window.wow.init();
